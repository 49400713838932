.fullEventDetailContent{
    padding: 4rem 7rem;
}
.fullEventDetailImg{
    width: 30%;
}
.fullEventBanner{
    background: url("../../../images/green-watercolor.jpg");
    background-position: center;
    background-size: cover;
    margin-top: 5rem !important;
}

@media only screen and (max-width: 600px) {
    .fullEventDetailContent {
        padding: 2rem 2rem;
    }
    .fullEventDetailImg {
        width: 75% !important;
    }
    #fullEventDetailSectThree{
        padding: 0 !important;
    }
}