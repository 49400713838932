#announcementContainer{
    padding: 4rem 2rem;
    user-select: none !important;
}
.modalContent3{
    margin: 1rem 0;
}
.modalContent3 p{
    margin: 0;
}
.announcementCol{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem;
}
.mapAnnouncementPara{
    overflow: hidden;
    /* height: 500px; */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}