.featured {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 1rem;
  justify-content: space-between;
}

.featuredItem {
  flex: 1;
  margin: 0px 20px;
  padding: 30px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}
.featuredItem a{
    text-decoration: none;
    color: initial;
}

.featuredTitle{
    font-size: 20px;
}

.featuredMoneyContainer{
    margin: 10px 0px;
    display: flex;
    align-items: center;
}

.featuredMoney{
    font-size: 30px;
    font-weight: 600;
}

.featuredMoneyRate{
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.featuredIcon{
    font-size: 2rem;
    /* margin-left: 5px; */
    color: #333;
}

.featuredIcon.negative{
    color: red;
}

.featuredSub{
    font-size: 15px;
    color: gray;
}