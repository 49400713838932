.page13Top{
    padding: 2rem 7rem;
    /* background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiPjxkZWZzPjxwYXR0ZXJuIGlkPSJwYXR0ZXJuXzY4T0hvViIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgd2lkdGg9IjkuNSIgaGVpZ2h0PSI5LjUiIHBhdHRlcm5UcmFuc2Zvcm09InJvdGF0ZSg0NSkiPjxsaW5lIHgxPSIwIiB5PSIwIiB4Mj0iMCIgeTI9IjkuNSIgc3Ryb2tlPSIjMTk0ZDMzIiBzdHJva2Utd2lkdGg9IjEiLz48L3BhdHRlcm4+PC9kZWZzPiA8cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI3BhdHRlcm5fNjhPSG9WKSIgb3BhY2l0eT0iMSIvPjwvc3ZnPg=='); */
}
.page13TopContent{
    padding: 2rem 7rem;
    text-align: center;
    max-width: 80%;
    margin: auto;
}
.page13TopTitle{
    margin-bottom: 1rem;
}
.page13TopPara{
    padding: 2rem;
    background: #fff;
    border-radius: 5px;
}
#prayerTopBx{
    display: flex;
}
.prayerImg{
    width: 500px;
    transform: scale(1);
    animation: scalePrayerImg 2s ease-in-out infinite;
}
.prayerImg img{
    width: 100%;
}

@keyframes scalePrayerImg{
    0%{
        transform: scale(1);
    }
    100%{
        transform: scale(1.1);
    }
}

/* Center */
.page13Center{
    padding: 4rem;
    background: url('../../images/light-texture-bg.jpg');
}
.page13CenterContent{
    /* padding: 4rem; */
    /* text-align: center; */
    padding: 0 7rem;
    max-width: 80%;
    margin: auto;
}
.page13CenterTitle{
    padding: 2rem 0;
    text-align: center;
}
.radio{
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 1rem;
}
.radio label{
    align-self: center;
}
input[type="text" i]{
    padding: .5rem;
    width: 100%;
    outline: 0;
    border: thin solid rgb(213, 213, 210);
    border-radius: 3px;
    margin-bottom: 1rem;
}
input[type="radio" i]{
    align-self: center;
    margin: 1rem 0;
}
.textArea{
    margin-top: 1rem;
}
.textArea textarea{
    padding: .5rem;
    width: 100%;
    height: 300px;
    outline: 0;
    border: thin solid rgb(213, 213, 210);
    border-radius: 3px;
    margin-bottom: 2rem;
}

/* Bottom */
.page13Bottom{
    padding: 4rem;
    background: #232323;
}
.page13BottomContent{
    padding: 0 4rem;
    text-align: center;
}
.page13BottomPara{
    padding: 2rem 0;
}
.page13BottomPara{
    color: #fff;
}

@media only screen and (max-width: 600px) {
    .page13Top {
    padding: 2rem;
    }
    .page13TopContent {
        padding: 0rem;
        text-align: center;
        max-width: 100%;
    }
    .page13TopPara {
        padding: 1rem;
    }
    .page13Center {
        padding: 2rem;
    }
    .page13CenterContent {
        padding: 0rem;
        max-width: 90%;
        /* text-align: center; */
    }
    /* .jtZqMD {
        width: auto;
        height: auto;
        text-align: center;
    } */
    .page13Bottom {
        padding: 2rem;
    }
    .page13BottomContent {
        padding: 0 0rem;
        text-align: center;
    }
    #prayerTopBx {
        display: block;
    }
    .prayerImg {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 2rem;
        animation: unset;
    }
    .prayerImg img{
        width: 70%;
    }
    .textArea textarea{
        height: 200px;
    }
  }
  @media only screen and (min-width: 600px) {
    
  }