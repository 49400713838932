.pagination-container {
  display: flex;
  list-style-type: none;
  padding-top: 2rem;
}

.pagination-item {
  padding: 0 12px;
  height: 50px;
  min-width: 50px;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 50%;
  line-height: 1.43;
  font-size: 1rem;
  font-weight: 700;
}

  .pagination-item.dots:hover {
      background-color: transparent;
      /* cursor: default; */
  }
  .pagination-item:hover {
      /* background-color: rgba(0, 0, 0, 0.04); */
      background-color: rgb(119, 62, 119);
      color: azure;
      cursor: pointer;
  }

.selected {
    /* background-color: rgba(0, 0, 0, 0.08); */
    background-color: purple;
    color: whitesmoke;
}

.arrow::before {
  position: relative;
  /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
  content: '';
  /* By using an em scale, the arrows will size with the font */
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}

.arrow.left {
  transform: rotate(-135deg) translate(0%);
  font-size: 2rem;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow.right {
  transform: rotate(45deg);
  font-size: 2rem;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabled {
pointer-events: none;
}
/* 
.arrow::before {
  border-right: 0.12em solid rgba(0, 0, 0, 0.43);
  border-top: 0.12em solid rgba(0, 0, 0, 0.43);
} */

.arrow:hover {
  background-color: transparent;
  cursor: default;
}

@media only screen and (max-width: 600px) {
  .pagination-container {
    display: flex;
    list-style-type: none;
    justify-content: center;
}
}
    
