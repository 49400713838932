.title1{
    background: aliceblue;
    display: flex;
    flex-direction: column;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 7px;
}

@media screen and (max-width: 960px) {
    .text_box{
        width: 100% !important;
    }
    .text_box textarea{
        width: 100% !important;
    }
}