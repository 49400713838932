.page6Bx1{
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiPjxkZWZzPjxwYXR0ZXJuIGlkPSJwYXR0ZXJuXzY4T0hvViIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgd2lkdGg9IjkuNSIgaGVpZ2h0PSI5LjUiIHBhdHRlcm5UcmFuc2Zvcm09InJvdGF0ZSg0NSkiPjxsaW5lIHgxPSIwIiB5PSIwIiB4Mj0iMCIgeTI9IjkuNSIgc3Ryb2tlPSIjMTk0ZDMzIiBzdHJva2Utd2lkdGg9IjEiLz48L3BhdHRlcm4+PC9kZWZzPiA8cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI3BhdHRlcm5fNjhPSG9WKSIgb3BhY2l0eT0iMSIvPjwvc3ZnPg==');
    padding: 4rem;
}
.page6Header, .page6Title, .page6Para{
    text-align: center;
}
.page6Title{
    padding: 1rem 0;
}
.page6Para{
    background-color: #fff;
    padding: 1rem;
    border-radius: 5px;
}
.page6TxtPara{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.page6Txt h3{
    font-family: 'fontawesome';
    color: #990000;
}

.page6Bx2{
    display: flex;
    gap: 2rem;
    padding: 2rem 6rem;
}
.page6Bx2 .page6BxOverlay1, .page6Bx2 .page6BxOverlay2{
    background: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url('../../images/splash3.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    border-radius: 6px;
    filter: brightness(.6);
    transform: scale(1);
    transition: transform .2s;
}
.page6Bx2 .page6BxOverlay1:hover, .page6Bx2 .page6BxOverlay2:hover{
    transform: scale(1.1);
}

.page6Bx3{
    padding: 4rem;
}
.page6Bx3 .page6Bx3Content{
    display: flex;
    gap: 2rem;
}
.page6Bx3Content .page6Img{
    flex: 1;
}
.page6Bx3Content .page6Img iframe{
    width: 100%;
    height: 250px;
}
.page6Bx3Content .page6Img img{
    width: 80%;
    border-radius: 6px;
}
.page6Bx3Content .page6Txt{
    flex: 2;
}
.page6Bx4{
    padding: 4rem 0;
}
.page6Bx5{
    padding-top: 5rem;
}
.page6Bx5Title{
    text-align: center;
}
.page6Bx5Img{
    width: 200px;
    height: 200px;
    /* border-radius: 50%; */
    margin: auto;
    padding: 1rem 0;
}
.page6Bx5Img img{
    border-radius: 50%;
    width: 200px;
    height: 200px;
}

@media only screen and (max-width: 600px) {
    .page6Bx1 {
        padding: 2rem;
    }
    .page6Bx2 {
        display: block;
        /* gap: 2rem; */
        padding: 2rem 2rem;
    }
    .page6BxOverlay2 {
        margin-top: 2rem;
    }
    .page6Bx3 {
        padding: 2rem;
    }
    .page6Bx3 .page6Bx3Content {
        display: block;
        text-align: center;
        margin-bottom: -2rem;
    }
    .page6Bx3Content .page6Img {
        border: 5px solid rgb(6, 143, 58);
    }
  }
  @media only screen and (min-width: 600px) {
    
  }