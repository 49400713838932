#allEventsOverlay{
    transition: transform .25s ease-in-out
}
#allEventsOverlay:hover{
    transform: scale(1.1);
}
#allEventsTxt{
    font-family: 'Tangerine', cursive;
    font-size: 2.5rem;
}
.page4TopLeft a, .page4TopCenter a, .page4TopRight a{
    text-decoration: none;
    color: #111;
}
.page4TopLeft a:hover, .page4TopCenter a:hover, .page4TopRight a:hover{
    text-decoration: none;
    color: #111;
}

.page4Top{
    display: flex;
    gap: 1rem;
    padding: 4rem;
}
.page4CenterBx{
    display: flex;
    gap: 1rem;
}
.page4TopLeft, .page4TopCenter, .page4TopRight{
    flex: 1;
}
.page4TopLeft .overlayTxt, .page4TopCenter .overlayTxt, .page4TopRight .overlayTxt{
    background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url('../../images/splash1.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}
/* .page4TopLeft .overlayTxt{
    background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url('../../images/splash1.jpg');
}
.page4TopLeft .overlayTxt{
    background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url('../../images/splash1.jpg');
} */
.overlayTxt .txtGreen{
    color: #006633;
}
.overlayTxt .txtWhite{
    color: #fff;
}
.txtOne, .txtTwo{
    text-align: center;
    margin-top: 1rem;
}
.btnBx{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    margin: 1rem 0;
    text-align: center;
}
.spanTxt{
    text-align: center;
}
.spacerBx{
    width: 100%;
    padding: 6rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.spacer{
    width: 100px;
    height: 2px;
    background: #abb8c3;
}
.page4Center{
    padding: 4rem;
}
.page4CenterTop{
    margin-bottom: 4rem;
}
.page4CenterTop .page4CenterTopTitle{
    text-align: center;
}
.page4CenterTop .page4CenterTopBtn{
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 1rem;
}
.page4Bottom{
    /* padding: 6rem 4rem 4rem 4rem; */
    flex: 1;
}
.page4BottomContent{
    display: flex;
    gap: 1rem;
}
.page4Bottom .page4BottomTitle{
    text-align: center;
    padding: 2rem 0;
}
.page4ButtomCardBx{
    display: flex;
    gap: 1rem;
}
.page4ButtomCardBx .page4buttomCard1, .page4ButtomCardBx .page4buttomCard2, .page4ButtomCardBx .page4buttomCard3{
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    transform: scale(1);
    text-decoration: none;
    transition: transform 0.2s ease-in-out 0s;
    border-radius: 5px;
}
.page4ButtomCardBx .page4buttomCard1:hover, .page4ButtomCardBx .page4buttomCard2:hover, .page4ButtomCardBx .page4buttomCard3:hover{
    transform: scale(1.1);
    box-shadow: 1px 1px 1px gray;
}
.page4ButtomCardOverlay{
    /* background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../../images/splash3.jpg'); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}
.page4ButtomCardTextContent{
    background: #006633;
    padding: 2rem;
}

@media only screen and (max-width: 600px) {
    .page4Top {
        display: block;
        gap: 1rem;
        padding: 2rem;
    }
    .page4TopLeft, .page4TopCenter, .page4TopRight {
        /* flex: 1 1; */
        margin: 2rem 0 4rem 0;
    }
    .spacerBx {
        width: 100%;
        padding: 0rem 0;
    }
    .page4Center {
        padding: 2rem;
    }
    .page4CenterTop .page4CenterTopBtn {
        display: block;
        text-align: center;
        /* justify-content: center; */
        /* gap: 10px; */
        margin-top: 1rem;
    }
    .page4BtnMid{
        padding: 1rem 0;
    }
    .btnBx {
        gap: 1rem;
    }
    .page4CenterBx {
        display: block;
        gap: 1rem;
    }
    .viewBtn{
        padding: 2rem 0;
    }
    .spacingDiv{
        padding: 0 !important;
    }
    .page4BottomContent {
        display: block;
        padding: 0 2rem 2rem 2rem;
    }
    .page4ButtomCardBx {
        display: block !important;
    }
    .page4buttomCard2{
        margin: 2rem 0;
    }
    .page4buttomCard3{
        margin-bottom: 4rem;
    }
  }
  @media only screen and (min-width: 600px) {
    .page4Top {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        padding: 4rem;
        /* flex-wrap: wrap; */
    }
    .page4CenterBx {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
    .page4BottomContent {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
  }
  @media only screen and (min-width: 992px) {
    .page4Top {
        display: flex;
    }
    .page4CenterBx {
        display: flex;
    }
    .page4BottomContent {
        display: flex;
    }
  }
@media only screen and (min-width: 1200px) {
}
