.extraPageContainer{
    padding: 4rem 4rem;
}
.extraPageContainerTitle{
    text-align: center;
    max-width: 80%;
    margin: auto;
}
.extraCardContainer{
    /* display: flex;
    justify-content: space-around; */
    padding: 4rem 0rem;
}

.extraContainer{
    position: relative;
    display: flex;
    justify-content: center;
}
.extraCard{
    width: 500px;
}
.extraBox{
    position: relative;
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    -webkit-box-reflect: below 15px linear-gradient(transparent, transparent, rgba(0, 0, 0, 0.5));
}
/* .extraBox:hover{
    color: #000;
} */
.extraBox:before{
    content: '';
    position: absolute;
    left: -10px;
    top: -10px;
    width: 100%;
    height: 100%;
    border: 4px solid #fff;
    background: #000;
    /* filter: url(#wavy); */
}
/* .extraBox:hover:before{
    background-color: #fff;
    color: #000;
    transition: 0.5s;
} */
.extraContent{
    position: absolute;
    padding: 20px;
    text-align: center;
    color: #fff;
    transition: 0.5s;
}
.extraContent p{
    margin-bottom: 0;
}
.extraBox:after{
    content: '';
    position: absolute;
    left: -10px;
    top: -10px;
    width: 50%;
    height: 100%;
    border: 4px solid #fff;
    border-right: none;
    background: rgba(255, 255, 255, 0.1);
    /* filter: url(#wavy); */
}
#extraSvg{
    width: 0;
    height: 0;
}