.nextHeader{
    margin: 0 auto 50px;
    max-width: 800px;
}
.nextHeader .nextTitle{
    text-align: center;
    font-weight: 900;
}
.nextHeader .nextTitle h2{
    color: #006633;
    font-family: 'Tangerine', cursive;
    font-size: 3rem;
}
.nextHeader .nextPara{
    font-weight: 300;
    line-height: 25px;
    text-align: center;
}
.nextContent{
    /* display: grid;
    grid-template-columns: 270px 270px 270px;
    grid-template-rows: 200px 200px;
    column-gap: 5rem;
    row-gap: 5rem;
    justify-content: center; */
}
.nextContent .contentBox{
    width: 100%;
}
.nextContent .contentBox img{
    width: 100%;
    height: 400px;
    /* object-fit: cover; */
    border-radius: 10px;
    transform: scale(1);
    transition: transform .2s ease-in-out;
}
.nextContent .contentBox img:hover{
    /* width: 100%; */
    transform: scale(1.1);
}
.nextContent .contentBox .contentText{
    padding-top: 1rem;
    font-size: 1rem;
    text-align: center;
}

@media only screen and (max-width: 600px){
    .nextContent{
        /* grid-template-columns: 1fr; */
        /* row-gap: 2rem; */
    }
    .contentText{
        padding: 1rem 0;
    }
    .nextHeader{
        margin: 0;
    }
    .nextHeader .nextTitle h2 {
        font-size: 2.2rem;
    }
}