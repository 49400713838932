#tornContainer{
    background: linear-gradient(rgba(18,30,34,.8), rgba(18,30,34,.8) ), url('../../images/banner2.png');
    background-position: center;
    background-size: cover;
    /* border: 25px solid #000;
    border-image: url(../../images/bannerH.png) 45% stretch; */
    border-left: 0;
    border-right: 0;
}
.tornContent{
    width: 50%;
    padding: 1rem 0;
}
.tornContent .contentHeader h2{
    color: #006633;
    font-family: 'Tangerine', cursive;
    font-size: 3rem;
    margin: 0 auto 10px auto;
    text-align: center;
}
.tornContent .tornContentTitle{
    color: #fff;
    text-transform: uppercase;
    margin:  0 0 22px;
    text-align: center;
}
.tornContent .tornContentDesc{
    color: #fff;
    max-width: 850px;
    line-height: 25px;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .tornContent .contentHeader h1{
        font-size: 1.7rem;
        font-style: italic;
        color: green;
        margin: 0 auto 10px auto;
        text-align: center;
    }
    .tornContent {
        width: 100%;
        padding: 0 2rem;
    }
    .tornContent .contentHeader h2 {
        font-size: 2.2rem;
    }
    .tornContentTitle h2{
        font-size: 1.2rem;
    }
    .tornContentDesc p{
        font-size: .8rem;
    }
  }
  @media only screen and (min-width: 600px) {
    
  }