.sliderBx{
    padding-top: 2rem;
}
.galleryBx{
    padding: 4rem 7rem;
}
.galleryBxTxt{
    text-align: center;
    padding: 1rem;
}

@media only screen and (max-width: 600px) {
    .galleryBx {
        padding: 4rem 2rem;
    }
    .model.open {
        height: 100%;
    }
  }
  @media only screen and (min-width: 600px) {
  }
  @media only screen and (min-width: 992px) {
  }
@media only screen and (min-width: 1200px) {
}