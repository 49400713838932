.banner{
    background: linear-gradient(rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url('../../images/web3.jpg');
    background-position: center;
    background-size: contain;
    background-repeat: repeat-x;
    border-top: 20px solid #008753;
    /* border-bottom: 20px solid #008753; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 4rem;
    /* align-items: center; */
    user-select: none;
}

.bannerCenter{
    display: flex;
    flex-direction: column;
    gap: 5rem;
    align-self: center;
}
blockquote{
    /* height: 30px; */
}

blockquote h2:before { 
    content: open-quote;
    font-weight: bold;
    font-size: 6rem;
    color:#889c0b;
    line-height: 0;
} 
    /* blockquote h2:after { 
    content: close-quote;
    font-weight: bold;
    font-size: 6rem;
    color:#889c0b;
  
} */

.bannerTitle h2{
    color: #990000;
    max-width: 50%;
    margin: auto;
    text-align: center;
}

.banner .bannerBtn{
    border: 2px solid;
    border-radius: 30px;
    box-sizing: border-box;
    background: red;
    color: #000;
    display: inline-block;
    font-weight: 900;
    min-width: 125px;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}
.banner .bannerBtn:hover{
    background-color: #fff;
}
.navyblue{
    /* background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiPjxkZWZzPjxwYXR0ZXJuIGlkPSJwYXR0ZXJuXzY4T0hvViIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgd2lkdGg9IjkuNSIgaGVpZ2h0PSI5LjUiIHBhdHRlcm5UcmFuc2Zvcm09InJvdGF0ZSg0NSkiPjxsaW5lIHgxPSIwIiB5PSIwIiB4Mj0iMCIgeTI9IjkuNSIgc3Ryb2tlPSIjMTk0ZDMzIiBzdHJva2Utd2lkdGg9IjEiLz48L3BhdHRlcm4+PC9kZWZzPiA8cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI3BhdHRlcm5fNjhPSG9WKSIgb3BhY2l0eT0iMSIvPjwvc3ZnPg=='); */
    background: url('../../images/dark2.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
    padding: 0 7rem;
    user-select: none;
}
.navyblue .navyblueContainer{
    display: flex;
    justify-content: center;
    /* background-color: #fff; */
}
.navyblueContentLeft, .navyblueContentRight{
    /* width: 50%;
    height: 250px; */
    /* border-radius: 5px;  */
    padding: 2rem;
    background-color: transparent;
}
/* .navyblueContentLeft iframe{
    width: 100%;
    height: 100%;
    border: 0; 
} */
.navyblueContentLeft .icon, .navyblueContentRight .icon{
    text-align: center;
}
.navyblueContentLeft .title, .navyblueContentRight .title{
    display: flex;
    justify-content: center;
}
.navyblueContentLeft .title a:hover, .navyblueContentRight .title a:hover{
    text-decoration: underline;
    text-decoration-color: #990000;
}
.navyblueContentLeft .title h2, .navyblueContentRight .title h2{
    color: #fff;
    font-size: 25px;
    font-weight: 900;
    margin-right: 10px;
    text-decoration: none;
    text-align: center;
}
.navyblueContentLeft .desc, .navyblueContentRight .desc{
    font-weight: 300;
    margin-top: 10px;
    text-decoration: none;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 1rem;
}
.navyblueContentLeft .desc, .navyblueContentRight .desc{
    color: #fff;
    font-weight: 700;
}
.divider{
    width: 4px;
    height: 80px;
    background-color: #990000;
    border-radius: 2px;
    align-self: center;
}
.markerBanner{
    margin-bottom: 15px;
    font-size: 3rem !important;
    color: #fff;
}
.angleBanner{
    align-self: center;
    color: #fff;
}
.title a{
    text-decoration: underline;
    color: #990000;
}
#bannerLinks{
    text-decoration: none;
}

@media only screen and (max-width: 600px) {
    .bannerCenter{
        display: flex;
        flex-direction: column;
        gap: 5rem;
        align-self: center;
    }
    .banner .bannerBtn{
        margin-bottom: 2rem;
    }
    .navyblue .navyblueContainer{
        display: block;
    }
    .divider{
        width: 100%;
        background-color: #000;
        height: 2px;
        align-self: center;
    }
    .banner{
        padding: 2rem;
    }
    .bannerTitle h2{
        max-width: 100%;
        font-size: 1.2rem;
    }
    .navyblueContentLeft{
        width: 100%;
    }
    .navyblue {
        padding: 0;
    }
  }
  @media only screen and (min-width: 600px) {
    
  }