#register_container{
    background-color: #12110f;
    background-image: url('../../../../images/lock2Bg.jpg');
    background-size: contain;
    background-position: 100% 0;
    background-repeat: no-repeat;
}
.register_title{
    font-size: 50px;
}
.register_form{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}
.register_form > label{
    font-size: 20px;
    margin: 10px 0;
}
.register_form > input{
    padding: 10px;
    background-color: #fff;
    border: thin solid lightslategray;
    outline: 0;
}
.register_button{
    margin-top: 20px;
    background-color: teal;
    color: #fff;
    border: none;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
}
.register_login_button{
    background-color: lightcoral;
    cursor: pointer;
    border: none;
    margin-top: 20px;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
}

.wrap{
    width: 100%;
    display: flex;
}
.icon{
    padding: 10px;
    min-width: 50px;
    text-align: center;
    cursor: pointer;
}
.field{
    width: 100%;
    padding: 10px;
}