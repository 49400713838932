.ourMissionContainerTitle{
    padding: 0 5rem;
}
.ourMissionContainerBody{
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; */
    min-height: 100vh;
    width: 100%;
    /* background: #fff; */
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1002%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='url(%23SvgjsLinearGradient1003)'%3e%3c/rect%3e%3cpath d='M0 0L224.03 0L0 100.89z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M0 100.89L224.03 0L271.01 0L0 242.82z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M0 242.82L271.01 0L487.28 0L0 309.77z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M0 309.77L487.28 0L760.29 0L0 337.7z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M1440 560L877.49 560L1440 427.16999999999996z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M1440 427.16999999999996L877.49 560L619.0799999999999 560L1440 234.59999999999997z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M1440 234.60000000000002L619.0799999999999 560L595.4899999999999 560L1440 92.76000000000002z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M1440 92.75999999999999L595.4899999999999 560L224.4799999999999 560L1440 81.25999999999999z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1002'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='15.28%25' y1='-39.29%25' x2='84.72%25' y2='139.29%25' gradientUnits='userSpaceOnUse' id='SvgjsLinearGradient1003'%3e%3cstop stop-color='rgba(255%2c 255%2c 255%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-color='rgba(255%2c 255%2c 255%2c 1)' offset='0.3'%3e%3c/stop%3e%3cstop stop-color='rgba(255%2c 255%2c 255%2c 1)' offset='1'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e"); */
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1736%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='url(%23SvgjsLinearGradient1737)'%3e%3c/rect%3e%3cpath d='M0 0L224.03 0L0 100.89z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M0 100.89L224.03 0L271.01 0L0 242.82z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M0 242.82L271.01 0L487.28 0L0 309.77z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M0 309.77L487.28 0L760.29 0L0 337.7z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M1440 560L877.49 560L1440 427.16999999999996z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M1440 427.16999999999996L877.49 560L619.0799999999999 560L1440 234.59999999999997z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M1440 234.60000000000002L619.0799999999999 560L595.4899999999999 560L1440 92.76000000000002z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M1440 92.75999999999999L595.4899999999999 560L224.4799999999999 560L1440 81.25999999999999z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1736'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='15.28%25' y1='-39.29%25' x2='84.72%25' y2='139.29%25' gradientUnits='userSpaceOnUse' id='SvgjsLinearGradient1737'%3e%3cstop stop-color='rgba(255%2c 255%2c 255%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-color='rgba(255%2c 255%2c 255%2c 1)' offset='0.3'%3e%3c/stop%3e%3cstop stop-color='rgba(3%2c 255%2c 101%2c 1)' offset='1'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e"); */
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1956%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='url(%23SvgjsLinearGradient1957)'%3e%3c/rect%3e%3cpath d='M0 0L224.03 0L0 100.89z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M0 100.89L224.03 0L271.01 0L0 242.82z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M0 242.82L271.01 0L487.28 0L0 309.77z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M0 309.77L487.28 0L760.29 0L0 337.7z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M1440 560L877.49 560L1440 427.16999999999996z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M1440 427.16999999999996L877.49 560L619.0799999999999 560L1440 234.59999999999997z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M1440 234.60000000000002L619.0799999999999 560L595.4899999999999 560L1440 92.76000000000002z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M1440 92.75999999999999L595.4899999999999 560L224.4799999999999 560L1440 81.25999999999999z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1956'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='15.28%25' y1='-39.29%25' x2='84.72%25' y2='139.29%25' gradientUnits='userSpaceOnUse' id='SvgjsLinearGradient1957'%3e%3cstop stop-color='rgba(255%2c 255%2c 255%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-color='rgba(255%2c 255%2c 255%2c 1)' offset='0.3'%3e%3c/stop%3e%3cstop stop-color='rgba(1%2c 144%2c 57%2c 1)' offset='1'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e"); */
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask2134%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='url(%23SvgjsLinearGradient2135)'%3e%3c/rect%3e%3cpath d='M0 0L224.03 0L0 100.89z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M0 100.89L224.03 0L271.01 0L0 242.82z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M0 242.82L271.01 0L487.28 0L0 309.77z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M0 309.77L487.28 0L760.29 0L0 337.7z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M1440 560L877.49 560L1440 427.16999999999996z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M1440 427.16999999999996L877.49 560L619.0799999999999 560L1440 234.59999999999997z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M1440 234.60000000000002L619.0799999999999 560L595.4899999999999 560L1440 92.76000000000002z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M1440 92.75999999999999L595.4899999999999 560L224.4799999999999 560L1440 81.25999999999999z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask2134'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='15.28%25' y1='-39.29%25' x2='84.72%25' y2='139.29%25' gradientUnits='userSpaceOnUse' id='SvgjsLinearGradient2135'%3e%3cstop stop-color='rgba(255%2c 255%2c 255%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-color='rgba(255%2c 255%2c 255%2c 1)' offset='0.3'%3e%3c/stop%3e%3cstop stop-color='rgba(95%2c 144%2c 1%2c 1)' offset='1'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e");
    background-size: contain;
    padding: 7rem;
}
.ourMissionBxCard{
     position: relative;
    /* min-width: 320px; */
    width: 100%;
    height: 440px;
    box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
                inset -5px -5px 15px rgba(255, 255, 255, 0.1),
                5px 5px 15px rgba(0, 0, 0, 0.3),
                -5px -5px 15px rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    margin: 30px;
}
.ourMissionBx{
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    background: #fff;
    border: 2px solid #fff;
    border-radius: 15px;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.ourMissionBx:before{
    content: '';
    position: absolute;
    /* top: 0; */
    left: 0;
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.05);
    pointer-events: none;
}
.ourMissionBx:hover{
    transform: translateY(-50px);
    box-shadow: 0 40px 70px rgba(0, 0, 0, 0.5);
}
.ourMissionBxContent{
    padding: 20px;
    text-align: center;
}
.ourMissionContainerTitle p{
    font-size: 1.3rem;
    font-weight: 600;
}
.ourMissionBxContent h2{
     position: absolute;
    top: -10px;
    right: 30px;
    font-size: 8em;
    /* color: rgba(255, 255, 255, 0.05); */
    color: #777;
}
.ourMissionBxContent h3{
     font-size: 3em;
    color: lawngreen;
    z-index: 1;
    transition: 0.5s;
    /* padding: 20px; */
}
.ourMissionBxContent p{
     font-size: 2rem;
     font-weight: 500;
    /* color: rgba(255, 255, 255, 0.5); */
    color:rgba(0, 0, 0, 0.9);
    z-index: 1;
    transition: 0.5s;
    padding: 20px;
}
/* .ourMissionBx:before, .ourMissionBx:after{
    background: linear-gradient(235deg, #89ff00, #060c21, #00bcd4);
}
.ourMissionBxCard{
    padding: 20px;
    color: #fff;
} */
@media only screen and (max-width: 600px) {
    .ourMissionContainerBody{
        background-size: cover;
        padding: 2rem;
    }
    .ourMissionContainerTitle {
       padding: 0;
    }
    .ourMissionBxCard {
        margin: 0;
    }
    .ourMissionContainerTitle p {
        font-size: 1.1rem;
        font-weight: 500;
    }
    .ourMissionBxContent p {
        font-size: 1rem;
        padding: 0;
    }
    .ourMissionBxContent h2 {
        top: 0px;
        font-size: 3em;
    }
  }