#navbar{
    /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)); */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
}
.activeNavbar {
    background-color: #006633;
    position: fixed !important;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    padding-bottom: 1.5rem;
    transition: 0.25s;
  }
.top{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 2rem;
}
.top .eventTime{
    text-align: center;
    color: #fff;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    gap: 1rem;
    cursor: pointer;
}
.top .nav{
    display: flex;
    /* justify-content: space-around; */
    padding: 0 5rem !important;
    flex-wrap: nowrap !important;
}
.top .nav .logo{
    width: 200px;
}
.top .nav .logo img{
    width: 200px;
}
/* .top .nav .logo .logoText{
    margin-left: 3px;
    align-self: flex-start;
    color: #fff;
}
#logoTxt{
    letter-spacing: 3px;
}
#logoTxt2{
    font-size: 1.3rem;
    letter-spacing: 2px;
    margin-left: 4px;
    position: relative;
    top: -15px;
} */
.navLinks{
    align-self: center;
}
.navLinks .navList{
    list-style: none;
    margin: 0;
    padding: 0 40px;
    display: flex;
    justify-content: center;
    gap: 1rem;
}
.navListItems{
    color: #fff;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    padding: 15px 0;
    cursor: pointer;
    /* display: flex; */
    display: inline-flex;
    white-space: normal;
    gap: 5px;
}
.hoverAngleIcon{
    transition: transform .25s ease;
    align-self: flex-start;
}
.navListItems:hover .hoverAngleIcon{
    align-self: flex-start;
    transform: rotate(180deg);
}
.navListItems:nth-child(4){
    /* width: 100%; */
}
.navListItems:nth-child(5){
    /* width: 100%; */
}
/* .navListItems:last-child{
    width: 100%;
} */
.navLinks .navList .navListItems:not(:last-child){
    /* margin-right: 10px; */
}
/* MainMenu nav */
.notActiveMain{
    position: absolute;
    width: 100%;
    /* transform: translateY(calc(-100% - 1rem)); */
    /* height: 0; */
    /* opacity: 0; */
    transition: transform 1s ease;
    transform: translateY(-1000px);
    background: #0e1216;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    position: absolute;
    top: 0;
    left: 0;
    height: 92vh;
    z-index: 1;
    opacity: 1;
    /* transition: transform .8s ease; */
}
.mainListItems{
    background: #0e1216;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    position: absolute;
    top: 0;
    left: 0;
    height: 92vh;
    z-index: 1;
    opacity: 1;
    transition: transform .8s ease;
    
}
.mainListItemsTop{
    display: flex;
    justify-content: space-between;
    padding: 2rem 7rem;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
}
.closeMenu{
    cursor: pointer;
}
.closeMenu h3{
    /* transform-style: preserve-3d; */
    border: 2px solid yellow;
    border-radius: 5px;
    padding: 5px;
}
/* .closeMenu h3::after{
    content: "";
    position: absolute;
    background-color: yellow;
    color: yellow;
    height: 5px;
    width: 30px;
    transform: translateZ(-1px);
} */

/* MainMenu nav center */
.mainListItemsCenter{
    display: flex;
    gap: 6rem;
    padding: 0 7rem;
    color: #fff;
}

.mainListItemsCenterLeft{
    flex: 1;
}
.mainListItemsCenterLeftContainer h3{
    text-align: left;
    color: #fff;
}
.mainListItemsCenterLeftImg{
    width: 100%;
    /* height: 500px; */
    padding: 1rem 0;
}
.mainListItemsCenterLeftImg img{
    width: 60%;
}
.notActiveMainImg{
    width: 100%;
    /* height: 500px; */
    padding: 1rem 0;
}
.notActiveMainImg img{
    width: 100%;
}
.mainListItemsCenterLeftBottomTxt{
    display: flex;
    gap: 5rem;
}
.mainListItemsCenterLeftBottomTxt h4{
    font-size: 1rem;
    color: #fff;
}

.mainListItemsCenterRight{
    flex: 1;
}
.mainListItemsCenterRightTopTxt{
    display: flex;
    gap: 5rem;
}
.mainListItemsCenterRightTopTxt h2:hover{
    color: cyan;
}
.mainListItemsCenterRightTopPara{
    display: flex;
    gap: 10px;
    cursor: pointer;
}
.mainListHr{
    margin: 1rem 0rem 0rem 0;
}
.mainListItemsCenterRightNavContainer{
    display: flex;
    gap: 5rem;
}
.mainListItemsCenterRightList{
    list-style: none;
    text-align: left;
    padding: 0;
}
.mainListItemsCenterRightListItems{
    padding: 1rem 0;
}
.mainListItemsCenterRightListItems:first-child{
    font-size: 12px;
    color: #676263;
}
.mainListItemsCenterRightSocials{
    color: #bdbbb8;
    text-align: left;
    display: flex;
    gap: 1rem;
}
.mainListItemsCenterRightSocials a{
    color: #bdbbb8;
    text-align: left;
}
.mainListItemsCenterRightSocials .fa:not(:first-child){
    padding-left: 10px;
}

#links{
    text-decoration: none;
    color: #fff;
}
#links .mainListItemsCenterRightListItems{
    text-decoration: none;
    color: #fff;
    font-size: 1rem;
}
#topLinks{
    color: #fff;
    font-weight: 900;
    text-decoration: none;
    text-transform: uppercase;
    padding: 15px 0;
}
.navButtons{
    display: flex;
    gap: 1rem;
    align-self: center;
}
.navButtons #rightBtn, #leftBtn{
    border: 2px solid;
    border-radius: 30px;
    box-sizing: border-box;
    color: #fff;
    display: inline-block;
    font-weight: 900;
    min-width: 125px;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}
.navButtons #leftBtn{
    background-color: #008753 !important;
    border-color: #008753 !important;
    /* margin-right: 10px; */
}
.leftBtn{
    /* margin-right: 20px; */
    align-self: center;
}
.navButtons .rightBtn{
    background-color: transparent;
}

/* Hover */
.hover{
    color: white;
    position: absolute;
    margin-top: 2rem;
    list-style: none;
    /* background: #232e32; */
    /* background: #052517; */
    background: #041909;
    /* padding: 1rem 10px; */
    padding: 0;
    /* padding: 0 10px; */
    line-height: 1.8;
    border-radius: 5px;
    /* height: 0px; */
    /* opacity: 0; */
    height: 0;
    overflow: hidden;
    transition: all .25s ease;
}

.hover a{
    color: white;
    font-weight: 300;
    text-transform: capitalize;
    font-size: 1rem;
    text-decoration: none;
    transition: all .5s ease;
    /* padding: 5px; */
}
.hover a:hover{
    /* color: #990000; */
    /* font-size: 1.2rem; */
    color: #fff;
    letter-spacing: 1px;
}

.navListItems:hover .hover{
    /* height: 100%; */
    /* height: 25vh; */
    /* opacity: 1; */
    /* max-height: 1000px; */
    height: auto;
    padding: 1rem 10px;
    background: linear-gradient(0deg, rgba(4, 25, 9, 0.90), rgba(4, 25, 9, 0.90));
}

.openLinkBtn{
    color: white;
    width: 70px;
    height: 50px;
    cursor: pointer;
    font-size: 45px;
    display: block;
    z-index: 1;
}
.mobileNav{
    background: linear-gradient(0deg, rgba(4, 25, 9, 0.90), rgba(4, 25, 9, 0.90));
    width: 90%;
    height: auto;
    padding: 2rem;
    position: absolute;
    top: 0;
    right: 0px;
    /* display: flex; */
    text-align: center;
    transition: width 5s linear;
}
.mobileNavActive{
    background: linear-gradient(0deg, rgba(4, 25, 9, 0.90), rgba(4, 25, 9, 0.90));
    width: 0;
    height: auto;
    padding: 2rem;
    position: absolute;
    top: 0;
    right: -100px;
    display: flex;
    text-align: center;
    transition: width 5s linear;
}

.mobileNav .navList{
    list-style: none;
    padding: 0;
    margin: 0;
}

.mobileNav .navList .navListItems .hover{
    color: white;
    position: absolute;
    margin-top: 1rem;
    list-style: none;
    background: #232e32;
    padding: 1rem 10px;
    line-height: 4vh;
    border-radius: 5px;
    height: 0px;
    display: none;
    overflow: hidden;
    transition: all .8s ease;
}
.mobileNav .navList .navListItems:hover .hover{
    position: relative;
    display: block;
    height: 100%;
}

#provincesLink{
    display: flex;
    position: absolute;
    gap: 1rem;
    right: 7%;
    width: 0%;
    opacity: 0;
    display: none;
    justify-content: flex-start;
    flex-wrap: wrap;
    z-index: 2;
}
#provincesLink a{
    font-size: 13px !important;
    display: flex;
    gap: 1rem;
}
#provincesLink a:hover{
    letter-spacing: 1px;
}
.navListItems:hover #provincesLink{
    display: flex;
    width: 80%;
    opacity: 1;
    justify-content: flex-start;
}
#provincesLink a li .separator:not(:last-child){
    display: none;
}
.provinceNavCenter{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* gap: 1rem; */
    position: absolute;
    left: 85%;
    background: linear-gradient(45deg, black, transparent);
    width: 15%;
}
.provinceNavCenter .sideBlock{
    align-self: center;
    font-weight: 500
}
.provinceNavCenter a{
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
}
#footerHoverItems{
    font-size: 12px;
    font-weight: 500;
}
.provinceNavCenterNone{
    width: 0%;
    display: none;
}
.mobileNavFlexBtn{
    display: none;
}
.mobTime{
    display: none;
}
.mobAccordionList{
    background: linear-gradient(45deg, black, transparent);
}
.mobAccordionList.pro{
    background: linear-gradient(45deg, black, transparent);
    overflow: auto;
    height: 500px;
}


.mobAccordionList a{
    list-style: none;
    color: #fff;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    line-height: 3;
}

.adminLink a{
    color: #fff;
    text-decoration: none;
    font-weight: 600;
}

@media only screen and (max-width: 600px) {
    #navbar{
        height: 20%;
        background-color: #041909;
    }
    .top .nav .logo{
    display: block;
    }
    .top .nav .logo img {
        width: 150px;
    }
    .top .nav {
    display: flex;
    justify-content: space-between;
    /* gap: 1rem; */
    padding: 0 1rem !important;
    }
    .logoText h1{
        font-size: 2rem !important;
    }
    .navLinks{
        display: none;
    }
    .navButtons{
        display: none;
    }
    .mobileNav .navList .navListItems:hover .hover {
        line-height: 4vh;
        margin: 0;
    }
    .openLinkBtn {
        text-align: center;
        z-index: 1;
        font-size: 30px;
        position: absolute;
        right: 0;
        /* display: flex; */
        /* justify-content: space-between; */
        /* gap: 13rem; */
    }
    .mobileNavFlexBtn{
        display: flex;
        position: absolute;
        bottom: 5px;
        right: 10%;
        justify-content: center;
    }
    .mobileNavFlexBtnLinks a button{
        font-weight: 900;
    }

    .activeNavbar {
        height: 15% !important;
      }
      .mobileNav {
        z-index: 1;
    }
    .mobileNav .navList {
        text-align: left;
        margin-top: 5rem;
    }
    .navListItems{
        display: block;
        padding: 5px 0;
        font-family: 'Montserrat';
        border-bottom: thin solid #ccc;
        margin-bottom: 1rem;
    }
    .navList a{
        text-decoration: none;
    }
    .navListItems:first-child{
        color: #990000;
    }
    .navListItems:hover #provincesLink {
        display: block;
        width: 50%;
        opacity: 1;
        height: auto;
        /* justify-content: flex-start; */
    }
    #provincesLink a {
        display: flex;
        gap: 1rem;
        justify-content: center;
        font-size: 10px !important;
    }
    .mobileNavFlexBtnLinks button{
        min-width: 65px;
        padding: 5px 5px;
    }
    .hoverProvinces{
        height: auto;
        width: 50% !important;
        display: block !important;
        justify-content: center;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: unset !important;
        z-index: 0 !important;
    }
    .mobProvinceList{
        margin: 0;
        padding: 0;
        list-style: none;
        align-self: center;
    }
    .mobProvinceListItem{
        font-size: 1rem;
        font-weight: 700;
    }
    .mobProvinceListDropdown{
        height: auto;
        display: flex !important;
        flex-direction: column;
        gap: 7px;
        position: absolute;
        top: 35vh;
        left: 100%;
        /* background: linear-gradient(45deg, #232e32, transparent); */
        background-color: #232e32;
        padding: 1rem 4rem;
        /* width: !important; */
        border-radius: 5px;
        transform: translate(-50%, -50%);
        transition: left .5s linear;
        z-index: 0 !important;
        list-style: none;
    }
    .mobProvinceListDropdown a, .hideMobProvinceListItem a{
        text-decoration: none !important;
    }
    .mobProvinceListDropdownItems{
        font-size: 10px;
        color: #fff;
    }
    .hideMobProvinceListItem{
        height: auto;
        display: flex !important;
        flex-direction: column;
        gap: 7px;
        position: absolute;
        top: 35vh;
        left: -500%;
        /* background: linear-gradient(45deg, #232e32, transparent); */
        background-color: #232e32;
        padding: 1rem 4rem;
        /* width: !important; */
        border-radius: 5px;
        transform: translate(-50%, -50%);
        transition: left .5s linear;
        z-index: 0 !important;
        list-style: none;
    }
    .mobTime{
        display: block;
    }
    .mobOrdList{
        background: linear-gradient(45deg, black, transparent);
        max-height: 500px;
        overflow: unset;
        transition: max-height .5s linear;
    }
    .mobOrdList a{
        list-style: none;
        color: #fff;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
        line-height: 3;
    }
    .hideMobList{
        max-height: 0;
        overflow: hidden;
        transition: max-height 1s linear;
    }
    .hideMobList a{
        display: none;
    }
    #mobSocial{
        margin-top: 2rem;
    }
    
}
@media only screen and (min-width: 600px) {
    .openLinkBtn{
        display: block;
    }
    .mobileNav{
        display: block;
    }
    .navLinks{
        display: none;
    }
    .navButtons{
        display: none;
    }
    .top .nav {
        display: flex;
        justify-content: space-between;
        padding: 2rem;
    }
    .navListItems {
        padding: 1.5rem 0;
    }
    
  }@media only screen and (min-width: 992px) {
    .openLinkBtn{
        display: none;
    }
    .mobileNav{
        display: none;
    }
    .navLinks{
        display: block;
    }
    .navButtons{
        display: flex;
    }
  }
@media only screen and (min-width: 1200px) {

}
 