 /* Styles the h3 */
.mc__title h3{
  /* max-width: 235px;
  text-align: center; */
  font-size: 1rem !important;

  /* Replace with your brand's main text color */
  /* color: #000;
  margin: 25px auto 35px; */
}

/* Positions the form in the middle of the modal and ensures the content doesn't jump as status messages get added/removed */
.mc__form-container {
  width: 100%;
  margin: 0 auto;
  transition: height .2s ease;
}

/* Positions Text and form fields nicely in the middle with some spacing. */
.mc__form {
  /* display: grid;
  grid-template-rows: min-content min-content;
  grid-row-gap: 40px;
  justify-content: stretch;
  align-items: center;
  transition: height .2s ease; */
}

/* Seperates the input fields vertically */
/* .mc__field-container {
  display: grid;
  grid-row-gap: 30px;
} */

/* Provides styles for status messages */
.mc__alert--succes{
  color: lightseagreen;
}
.mc__alert--sending{
  color: #333233;
}
.mc__alert--error{
  color: red;
}

.mc__btn{
  /* border: 2px solid;
    border-radius: 30px; */
    color: #fff;
    display: inline-block;
    font-weight: 500;
    /* min-width: 125px; */
    padding: 11px 11px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    background: #008753;
    border: none;
}


/* ----------------------------------------------
 * Generated by Animista on 2021-4-1 16:18:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-loop
 * ----------------------------------------
 */
@-webkit-keyframes fade-loop {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-loop {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
    .mc__title {
      margin-top: 1rem;
      text-align: center;
    }
    .mc__field-container{
      text-align: center;
    }
}