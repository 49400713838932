#login_container{
    background-color: #d8cacb;
    background-image: url('../../../../images/lockBg.jpg');
    background-size: contain;
    background-position: 100% 0;
    background-repeat: no-repeat;
}
.login_title{
    font-size: 50px;
}
.login_form{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}
.login_form > label{
    font-size: 20px;
    margin: 10px 0;
}
.login_form > input{
    padding: 10px;
    background-color: #fff;
    border: thin solid lightslategray;
    outline: 0;
}
.login_button{
    margin-top: 20px;
    background-color: teal;
    color: #fff;
    border: none;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
}
.login_register_button{
    background-color: lightcoral;
    cursor: pointer;
    border: none;
    margin-top: 20px;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
}