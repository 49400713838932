/* @import url(//fonts.googleapis.com/css?family=Montserrat:300,400,500);

.contactPageGetinTouchContainer{
  clip-path: polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%);
} */


/* .contact2 {
  font-family: "Montserrat", sans-serif;
  color: #8d97ad;
  font-weight: 300;
	padding: 60px 0;
  margin-bottom: 170px;
  background-position: center top;
} */
/* .contact2 h1,
.contact2 h2,
.contact2 h3,
.contact2 h4,
.contact2 h5,
.contact2 h6 {
  color: #3e4555;
}

.contact2 .font-weight-medium {
  font-weight: 500;
}

.contact2 .subtitle {
  color: #8d97ad;
  line-height: 24px;
}

.contact2 .bg-image {
  background-size: cover;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.contact2 .card.card-shadow {
    -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
    box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
}

.contact2 .detail-box .round-social {
  margin-top: 100px;
}

.contact2 .round-social a {
  background: transparent;
  margin: 0 7px;
  padding: 11px 12px;
}

.contact2 .contact-container .links a {
  color: #8d97ad;
}

.contact2 .contact-container {
  position: relative;
  top: 200px;
}

.contact2 .btn-danger-gradiant {
  background: #ff4d7e;
  background: -webkit-linear-gradient(legacy-direction(to right), #ff4d7e 0%, #ff6a5b 100%);
  background: -webkit-gradient(linear, left top, right top, from(#ff4d7e), to(#ff6a5b));
  background: -webkit-linear-gradient(left, #ff4d7e 0%, #ff6a5b 100%);
  background: -o-linear-gradient(left, #ff4d7e 0%, #ff6a5b 100%);
  background: linear-gradient(to right, #ff4d7e 0%, #ff6a5b 100%);
}

 .contact2 .btn-danger-gradiant:hover {
  background: #ff6a5b;
  background: -webkit-linear-gradient(legacy-direction(to right), #ff6a5b 0%, #ff4d7e 100%);
  background: -webkit-gradient(linear, left top, right top, from(#ff6a5b), to(#ff4d7e));
  background: -webkit-linear-gradient(left, #ff6a5b 0%, #ff4d7e 100%);
  background: -o-linear-gradient(left, #ff6a5b 0%, #ff4d7e 100%);
  background: linear-gradient(to right, #ff6a5b 0%, #ff4d7e 100%);
}
#message{
  height: 300px !important;
  resize: none !important;
} */
/* .contactPageBannerContainer{
  background: url('../../images/web3.jpg') !important;
} */

@media only screen and (max-width: 600px) {
    .contactPageGetinTouchContainer{
      padding: 2rem !important;
    }
    .contactPageGetinTouchContainerSvg{
      padding: 2rem !important;
    }
    #contactPageIframe{
      margin-top: 1rem;
    }
    .round-social a{
      font-size: 1.5rem;
    }
}