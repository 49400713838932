#write_container{
    /* background-image: url('data:image/svbackground-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiPjxkZWZzPjxwYXR0ZXJuIGlkPSJwYXR0ZXJuX1J4dHZMIiBwYXR0ZXJuVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB3aWR0aD0iMTEuNSIgaGVpZ2h0PSIxMS41IiBwYXR0ZXJuVHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48bGluZSB4MT0iMCIgeT0iMCIgeDI9IjAiIHkyPSIxMS41IiBzdHJva2U9IiM4MDVERUUiIHN0cm9rZS13aWR0aD0iNSIvPjwvcGF0dGVybj48L2RlZnM+IDxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjcGF0dGVybl9SeHR2TCkiIG9wYWNpdHk9IjEiLz48L3N2Zz4=')g+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiPjxkZWZzPjxwYXR0ZXJuIGlkPSJwYXR0ZXJuX0VSN0MzWiIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgd2lkdGg9IjkuNSIgaGVpZ2h0PSI5LjUiIHBhdHRlcm5UcmFuc2Zvcm09InJvdGF0ZSg0NSkiPjxsaW5lIHgxPSIwIiB5PSIwIiB4Mj0iMCIgeTI9IjkuNSIgc3Ryb2tlPSIjMTk0ZDMzIiBzdHJva2Utd2lkdGg9IjEiLz48L3BhdHRlcm4+PC9kZWZzPiA8cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI3BhdHRlcm5fRVI3QzNaKSIgb3BhY2l0eT0iMSIvPjwvc3ZnPg=='); */
    background-color: beige;
    /* background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiPjxkZWZzPjxwYXR0ZXJuIGlkPSJwYXR0ZXJuXzJrWHRwbCIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgd2lkdGg9IjkuNSIgaGVpZ2h0PSI5LjUiIHBhdHRlcm5UcmFuc2Zvcm09InJvdGF0ZSg0NSkiPjxsaW5lIHgxPSIwIiB5PSIwIiB4Mj0iMCIgeTI9IjkuNSIgc3Ryb2tlPSIjNEQxOTE5IiBzdHJva2Utd2lkdGg9IjEiLz48L3BhdHRlcm4+PC9kZWZzPiA8cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI3BhdHRlcm5fMmtYdHBsKSIgb3BhY2l0eT0iMSIvPjwvc3ZnPg=='); */
    
}
.container{
    margin-top: 0rem;
}

.write_img{
    width: 70vw;
    height: 250px;
    border-radius: 10px;
    object-fit: cover;
    border-top: 5px solid lightcoral;
}

.writeForm{
    position: relative;
}
.write_FormGroup{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 30px;
}
.write_icon{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(121, 118, 118);
    font-size: 20px;
    cursor: pointer;
}
.write_input{
    font-size: 1.3rem;
    border: none;
    border-bottom: 2px solid teal;
    padding: 20px;
    width: 50vw;
}
.write_input:focus{
    outline: none;
}
.writeText{
    font-size: 20px;
    height: 100vh;
}

.write_desc textarea{
    width: 80%;
    height: 100px;
    border: thin solid lightslategray;
    outline: 0;
    font-size: 1rem;
    padding: 20px;
    overflow: hidden;
}
.writeSubmit{
    position: absolute;
    top: 20px;
    right: 50px;
    color: #fff;
    padding: 10px;
    background-color: teal;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
}

@media screen and (max-width: 765px) {
    .write_img{
        width: 100%;
    }

    .write_FormGroup{
        display: block;
        padding: 0;
    }
    .write_upload{
        margin: 5px 0 5px 0px;
    }

    .write_input {
        font-size: 1rem;
        border: none;
        height: 60px;
        margin: 10px 0 10px 0;
        border-bottom: 2px solid teal;
        padding: 5px;
        width: 100%;
    }

    .write_desc textarea {
        width: 100%;
        height: 100px;
        margin: 10px 0 10px 0;
        border: thin solid lightslategray;
        outline: 0;
        font-size: 1rem;
        padding: 5px;
        overflow: hidden;
    }

    .write_btn{
        padding: 0 !important;
        margin: 10px;
    }
}